<template>
  <List el="xsm" style="padding: 32px">
    <!-- <Text el="h2">{{ $route.params.id }}</Text> -->
    <List el="column-between" class="v-center">
      <Text el="h3" v-if="module">{{ module.label }} Module</Text>
      <Text el="h3" v-else>Module</Text>
      <!-- <Text el="h3">{{ selectedModule.label }} Module</Text> -->
      <!-- <div @click="section.collapse = true" style="cursor: pointer;">
        <span class="material-icons">close</span>
      </div> -->
      <Icon name="close" @click="close" />
    </List>
    <Text el="p" v-if="module != null">{{ module.description }}</Text>
    <!-- <h4 el>Content</h4> -->
    <div el="list xxsm" class="module-content" v-if="module != null">
      <div v-for="item in module.content" :key="item">
        <div v-if="modules.library[item].type == 'html'">
          <a :href="modules.library[item].location" el="link" target="_blank">{{
            modules.library[item].label
          }}</a>
        </div>
      </div>
    </div>
  </List>
</template>
<script>
// import { reactive } from "vue";
import router from "../router";
import { db } from "@/firebase";
import { doc, getDoc } from "firebase/firestore";
import Icon from "@/components/btn/Icon.vue";
// const module = reactive({});

export default {
  components: {
    Icon,
  },
  data() {
    return {
      window: this.$store.state.main.window,
      user: this.$store.state.main.user,
      main: this.$store.state.main.layout.main,
      section: this.$store.state.main.layout.section,
      module: null,
      modules: {
        library: {
          j1: {
            label: "JAVA Data Types & Variables",
            location:
              "/Library/1-Java/2 - Java Variables/Data Types_Variables.html",
            img: "",
            type: "html", //html, file, video, img
          },
          j2: {
            label: "JAVA Operator",
            location: "/Library/1-Java/3 - Java Operators/Java_Operator.html",
            img: "",
            type: "html", //html, file, video, img
          },
          j3: {
            label: "JAVA Control Flows",
            location:
              "/Library/1-Java/4 - Java Control Flows/Java Control Flows.html",
            img: "",
            type: "html", //html, file, video, img
          },
          j4: {
            label: "JAVA Loops",
            location: "/Library/1-Java/5 - Java loops/Java Loops.html",
            img: "",
            type: "html", //html, file, video, img
          },
          j5: {
            label: "JAVA Arrays",
            location: "/Library/1-Java/6 - Java Arrays/Java Arrays.html",
            img: "",
            type: "html", //html, file, video, img
          },
          j6: {
            label: "JAVA Methods",
            location: "/Library/1-Java/7 - Java Methods/Java Method.html",
            img: "",
            type: "html", //html, file, video, img
          },
          j7: {
            label: "JAVA Class & Object",
            location:
              "/Library/1-Java/8 - Java Class & Object/Class & Object.html",
            img: "",
            type: "html", //html, file, video, img
          },
          j8: {
            label: "Java Encapsulation & Abstraction",
            location:
              "/Library/1-Java/9 - Java Encapsulation & Abstraction/Java Encapsulation & Abstraction.html",
            img: "",
            type: "html", //html, file, video, img
          },
          j9: {
            label: "Java Inheritance",
            location:
              "/Library/1-Java/10 - Java Inheritance/Java Inheritance.html",
            img: "",
            type: "html", //html, file, video, img
          },
          j10: {
            label: "Java Polymorphism",
            location:
              "/Library/1-Java/11-Interface & Polymorphisim/Polymorphism.html",
            img: "",
            type: "html", //html, file, video, img
          },
          m1: {
            label: "Maven Introduction",
            location: "/Library/2-Maven/Introduction to Maven.html",
            img: "",
            type: "html", //html, file, video, img
          },
          t1: {
            label: "TestNG - Part 1",
            location:
              "/Library/3-Testing Framework/TestNG/TestNG Testing Framework - Part I.html",
            img: "",
            type: "html", //html, file, video, img
          },
        },
      },
    };
  },
  watch: {
    "$route.params.id": function () {
      this.getModule();
    },
  },
  methods: {
    async getModule() {
      let routeParams = this.$route.params.id;
      if (routeParams != null) {
        const docRef = doc(db, "modules", this.$route.params.id);
        const docSnap = await getDoc(docRef);
        this.module = docSnap.data();
      }
    },
    close() {
      this.section.collapse = true;
      router.push("/dashboard/modules");
    },
  },
  mounted() {
    this.getModule();
  },
};
</script>
